import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Snackbar,
    Alert
} from "@mui/material";
import API from "../../api/api";
import CreateUsers from "./CreateUsers";
import DisableButton from "./components/DisableButton";
import EnableButton from "./components/EnableButton";
import ViewEditUser from "./ViewEditUser";

export default function Users() {
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    // Ensure that logged-in user is admin
    useEffect(() => {
        if (localStorage.getItem('user_type') !== 'Admin') {
            window.location.href = '/';
        }
    }, []);

    const fetchUsers = useCallback(async () => {
        try {
            const response = await API.get("/staff/get_staff_by_institution/", {
                params: {
                    institution_id: localStorage.getItem('institution_id')
                }
            });
            setUsers(response.data);
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to load users', severity: 'error' });
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Grid container
              direction="column"
              sx={{
                  bgcolor: 'background.paper',
                  borderRadius: 2,
                  p: 2,
                  m: 2,
                  width: '100%',
                  maxWidth: '1200px',
                  mx: 'auto',
              }}
        >
            <Grid item container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 2 }}
            >
                <Typography variant="h4">User List</Typography>
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Create New User
                </Button>
            </Grid>
            <CreateUsers open={open} setOpen={setOpen} />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Staff Type</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => (
                            <TableRow key={user.id} hover>
                                <TableCell>{user.first_name}</TableCell>
                                <TableCell>{user.last_name}</TableCell>
                                <TableCell>{user.staff_type}</TableCell>
                                <TableCell>{user.status}</TableCell>
                                <TableCell>
                                    <Grid container spacing={1} justifyContent="center">
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedUser(user);
                                                    setOpenEdit(true);
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            <ViewEditUser open={openEdit} setOpen={setOpenEdit} user={selectedUser} />
                                        </Grid>
                                        <Grid item>
                                            {user.suspended ? <EnableButton user={user} /> : <DisableButton user={user} />}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}
