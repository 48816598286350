import {
    Alert,
    Button,
    Checkbox,
    FormControl,
    FormGroup,
    Grid,
    Radio,
    RadioGroup, Snackbar,
    TextField,
    Typography
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from '@mui/icons-material/Clear';
import {useNavigate} from "react-router-dom";
import API from "../../../api/api"

export default function PatientRegistrationForm({
                                                    guardianFirstName,
                                                    guardianLastName,
                                                    isChildAccount,
                                                    patientType,
                                                    // handeFormSubmit,
                                                    checked,
                                                    setChecked,
                                                    setPatientType,
                                                    setGuardianFirstName,
                                                    setGuardianLastName,
                                                    setSex,
                                                    setIsChildAccount,
                                                    setPhoto,
                                                 }) {
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        formState: {errors},
        setError,
        clearErrors,
        reset
    } = useForm({
        defaultValues: {
            email: "",
            first_name: "",
            last_name: "",
            phone: "",
            sex: "",
            guardian_first_name: "",
            guardian_last_name: "",
            is_child_account: false,
            patient_type: "adult",
            date_of_birth: "",
            id_or_passport:""
        },
    });
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('success');
    const [isSubmitting, setIsSubmitting] = useState(false); // To handle submit button state


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    async function handleFormSubmit(data) {
        setIsSubmitting(true); // Disable button during submission
        clearErrors();
        try {
            data.institution_id = localStorage.getItem("institution_id");
            data.is_child_account = checked;
            data.staff_id = localStorage.getItem("user_id");
            if (checked === false) {
                delete data["guardian_first_name"]
                delete data["guardian_last_name"]
            }
            // console.log(data)
            await API.post("patient/create/", data)
                .then((resp) => {
                    if (resp.status === 201) {
                        reset();
                        navigate("/")
                    }
                })
                .catch((e) => {});
        } catch (error){
            if (error.response && error.response.data) {
                const apiErrors = error.response.data;
                for (const [key, messages] of Object.entries(apiErrors)) {
                    if (key in data) {
                        setError(key, {
                            type: "manual",
                            message: messages.join(", "),
                        });
                    } else {
                        setAlertMessage(messages.join(", "));
                        setAlertSeverity('error');
                        setOpenAlert(true);
                    }
                }
            } else {
                setAlertMessage("An error occurred. Please try again");
                setAlertSeverity('error');
                setOpenAlert(true);
            }
        }
    }

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>

            <Grid container //onSubmit={handleFormSubmit}
                  style={{
                      backgroundColor: "white",
                      maxWidth: "96%",
                      paddingLeft: "10px",
                      marginLeft: "20px",
                      borderRadius: 5,
                      paddingBottom: 10,
                  }}
            >
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={checked} value={patientType} onChange={(event) => {
                                setPatientType("child")
                                setChecked(event.target.checked)
                                setIsChildAccount(!isChildAccount)
                            }}
                            />}
                            label="Child Account?"/>
                    </FormGroup>
                </Grid>
                {checked ? (
                    <>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControl>
                                    <Controller
                                        name="guardian_first_name"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="Parent/Guardian First Name"
                                                variant="outlined"
                                                style={{
                                                    maxWidth: 460,
                                                    paddingBottom: 15,
                                                }}
                                                onChange={(event) => {
                                                    setGuardianFirstName(event.target.value)
                                                }}
                                                value={guardianFirstName}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControl>
                                    <Controller
                                        name="guardian_last_name"
                                        control={control}
                                        rules={{required: "First name is required"}}
                                        defaultValue=""
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                id={"guardian_last_name"}
                                                type={"text"}
                                                label="Parent/Guardian Last Name"
                                                variant="outlined"
                                                style={{
                                                    maxWidth: 460,
                                                    paddingBottom: 15,
                                                }}
                                                onChange={(event) => {
                                                    setGuardianLastName(event.target.value)
                                                }}
                                                value={guardianLastName}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
                <Grid item xs={6}>
                    <Controller
                        name="first_name"
                        control={control}
                        rules={{
                            required: "First name is required",
                            minLength: {
                                value: 3,
                                message: "First name must be at least 3 characters long",
                            },
                    }}
                        render={({field}) => (
                            <TextField
                                {...field}
                                id={"first_name"}
                                label={"First name"}
                                type={"text"}
                                variant={"outlined"}
                                style={{
                                    minWidth: 460,
                                    paddingBottom: 15,
                                }}
                                error={!!errors.first_name}
                                helperText={errors.first_name ? errors.first_name.message : null}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="last_name"
                        control={control}
                        rules={{
                            required: "Last name is required",
                            minLength: {
                                value: 3,
                                message: "Last name must be at least 3 characters long",
                            },
                    }}
                        render={({field}) => (
                            <TextField
                                {...field}
                                id={"last_name"}
                                label={"last name"}
                                type={"text"}
                                variant={"outlined"}
                                style={{
                                    minWidth: 460,
                                    paddingBottom: 15,
                                }}
                                error={!!errors.last_name}
                                helperText={errors.last_name ? errors.last_name.message : null}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="phone"
                        control={control}
                        rules={{
                            required: "Mobile number is required",
                            pattern: {
                                value: /^\+254\d{9}$/,
                                message: "Phone number must start with +254 and have 9 digits after the prefix"
                            }
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Mobile Number"
                                variant="outlined"
                                style={{
                                    minWidth: 460,
                                    paddingBottom: 15,
                                }}
                                onChange={(event) => {
                                    // Ensure phone number starts with +254
                                    if (!event.target.value.startsWith("+254")) {
                                        field.onChange("+254" + event.target.value.replace(/^\+?254/, ""));
                                    } else {
                                        field.onChange(event.target.value);
                                    }
                                }}
                                error={!!errors.phone}
                                helperText={errors.phone ? errors.phone.message : null}
                            />
                        )}
                    />
                </Grid>


                <Grid item xs={6}>
                    <Controller
                        name="id_or_passport"
                        control={control}
                        rules={{required: "ID/Passport number is required"}}
                        render={({field}) => (
                            <TextField
                                {...field}
                                id={"id_or_passport"}
                                label={"ID/Passport number"}
                                type={"text"}
                                variant={"outlined"}
                                style={{
                                    minWidth: 460,
                                    paddingBottom: 15,
                                }}
                                error={!!errors.id_or_passport}
                                helperText={errors.id_or_passport ? errors.id_or_passport.message : null}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography>Sex</Typography>
                    <Controller
                        name="sex"
                        control={control}
                        rules={{ required: "Sex is required" }}
                        render={({ field }) => (
                            <FormControl component="fieldset">
                                <RadioGroup
                                    {...field}
                                    value={field.value || ""}
                                    onChange={(e) => field.onChange(e.target.value)}
                                >
                                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                                </RadioGroup>
                            </FormControl>
                        )}
                    />
                    {errors.sex && <Typography color="error">{errors.sex.message}</Typography>}
                </Grid>

                <Grid item>
                    <Controller
                        control={control}
                        name="date_of_birth"
                        rules={{required: "Please set a Date of Birth"}}
                        render={({field}) => (
                            <TextField
                                {...field}
                                label="Date of Birth"
                                type="date"
                                defaultValue="2017-05-24"
                                sx={{width: 250}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin={"normal"}
                                error={!!errors.date_of_birth}
                                helperText={errors.date_of_birth ? errors.date_of_birth.message : null}
                            />
                        )}/>
                </Grid>
                {/*	Photo upload section*/}
                <Grid item xs={6}>
                    <Typography>
                        Upload Photo(optional)
                    </Typography>
                    <input type={"file"}
                           accept={"application/pdf, image/jpg, image/jpeg,image/png"}
                           onChange={e => setPhoto(e.target.files[0])}
                    />
                </Grid>
                {/*	email field*/}
                <Grid item xs={6}>
                    <Controller
                        name="email"
                        control={control}
                        rules={{required: "Email is required"}}
                        render={({field}) => (
                            <TextField
                                {...field}
                                id={"email"}
                                label={"email"}
                                type={"email"}
                                variant={"outlined"}
                                style={{
                                    minWidth: 460,
                                    paddingBottom: 15,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Button
                    type={"submit"}
                    variant={"outlined"}
                    onClick={() => navigate("/")}

                    endIcon={<ClearIcon fontSize={"large"}/>}
                    style={{
                        borderRadius: 20,
                        backgroundColor: "#d76215",
                        color: "white",
                        marginRight: 5,
                    }}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant={"outlined"}
                    disabled={isSubmitting} // Disable during submission
                    endIcon={<CheckCircleIcon fontSize={"large"}/>}
                    style={{
                        borderRadius: 20,
                        backgroundColor: "#77c0a3",
                        color: "white",
                        marginRight: 5,
                    }}
                >
                    {isSubmitting ? "Submitting..." : "Register Patient"}
                </Button>

                <Snackbar
                    open={openAlert}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                >
                    <Alert
                        onClose={handleClose}
                        severity={alertSeverity}
                        variant="filled"
                        sx={{
                            width: '100%',
                            fontSize: '1.2rem',
                            padding: '20px',
                        }}
                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Grid>
        </form>
    );
}
