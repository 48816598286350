import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import API from "../../api/api";
import Card from "./components/Card";
import axios from "axios";

export default function Dashboard() {
    const [appointment, setAppointment] = useState("");
    const [patient, setPatient] = useState("")
    const [doctor, setDoctor] = useState("")
    const [staff, setStaff] = useState("")

    const appointments_count = API.get("appointments/");
    const patients_count = API.get("patient/");
    const doctors_count = API.get("staff/");
    const staff_count = API.get("staff/");
    const update_logged_in_institution = API.patch("staff/update_hospital_logged_in/", {
        institution_id: localStorage.getItem("institution_id"),
        staff_id: localStorage.getItem("user_id"),
    })
    useEffect(() => {
        axios.all([appointments_count, patients_count, doctors_count, staff_count], update_logged_in_institution).then(
            axios.spread((...allData) => {
                setAppointment(allData[0].data);
                setPatient(allData[1].data);
                setDoctor(allData[2].data);
                setStaff(allData[3].data);
            })
        );
    }, []);
    return (
        <>
            <Grid container direction={"row"} justifyContent={"space-evenly"}>
                {/*Quick Stats section*/}
                <Card imgname={"Patients"} imgalt={"Patients"} num={patient.count}/>
                <Card imgname={"Doctors"} imgalt={"Doctors"} num={doctor.count}/>
                <Card imgname={"Staff"} imgalt={"Staff"} num={staff.count}/>
                <Card imgname={"Appointment"} imgalt={"Appointment"} num={appointment.count}/>
            </Grid>
        </>
    );
}
